<template>
  <div v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="popup" v-show="show">
      <img v-if="$t('moenySteps.i_img')=='zh'" src="@/assets/common/openllq.png" style="width: 350px;float: right;" />
      <img v-if="$t('moenySteps.i_img')=='en'" src="@/assets/common/openllq1.png" style="width: 350px;float: right;" />
    </div>
    <div id="moneySteps-layout">
      <div class="ms-page-header">
        <el-page-header :title="$t('common.i_back')" :content="$t('moenySteps.i_earningSteps')" @back="$router.push('/index')" style="float: left"></el-page-header>
        <div style="float: right;height: 24px;">
          <svg style="vertical-align: middle;" t="1655452993620" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2356" width="24" height="24" fill="white">
            <path d="M783.53 240.47C641.08 98.02 413.8 91.41 263.09 220.03V167.1c0-17.67-14.33-32-32-32s-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32s-14.33-32-32-32h-48.15c125.55-101.54 310.66-94.06 427.34 22.62 124.75 124.77 124.75 327.8 0 452.56-124.78 124.75-327.78 124.75-452.56 0C225.28 677.84 192 597.48 192 512c0-17.67-14.33-32-32-32s-32 14.33-32 32c0 102.58 39.94 199.02 112.47 271.53 74.86 74.86 173.19 112.3 271.53 112.3 98.33 0 196.67-37.44 271.53-112.3 149.7-149.72 149.7-393.34 0-543.06z" p-id="2357"></path>
            <path d="M512 288c-17.67 0-32 14.33-32 32v185.38c0 12.81 5 24.88 14.06 33.94l123.31 123.31c6.25 6.25 14.44 9.38 22.62 9.38s16.38-3.12 22.62-9.38c12.5-12.5 12.5-32.75 0-45.25L544 498.75V320c0-17.67-14.33-32-32-32z" p-id="2358"></path>
          </svg>
          <router-link to="/goldLog" style="text-decoration: none;color: white;vertical-align: middle;">{{$t('index.i_coinsLog')}}</router-link>
        </div>
      </div>

      <div style="">
        <div style="margin-top: 2%;margin-top: 5%;">
          <img style="width: 34px;vertical-align: middle;" src="@/assets/common/moneyIcon.png" />
          <img style="width: 34px;vertical-align: middle;margin-left: 3%;margin-right: 3%;" src="@/assets/images/moneySteps002.png" />
          <img style="width: 34px;vertical-align: middle;" src="@/assets/images/moneySteps003.png" />
        </div>
        <div style="margin-top: 5%;margin-bottom: 2%;">
          <span style="font-size: 14px;color: white;">*{{$t('moenySteps.i_moenyStepsMsg')}}*</span>
        </div>
        <div style="background-color: rgba(250, 244, 222, 1);padding: 4%;">
          <div style="text-align: left;margin-top: 5%;margin-bottom: 3%;">
            <svg style="vertical-align: middle;" t="1656052860005" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="20948" width="32" height="32">
              <path d="M512.045025 962.874851c-248.349251 0-449.65507-201.846124-449.65507-450.919876 0-248.984724 201.305819-450.830849 449.65507-450.830849 248.261247 0 449.565019 201.846124 449.565019 450.830849C961.610044 761.028727 760.306272 962.874851 512.045025 962.874851L512.045025 962.874851zM572.751642 289.933345l-69.211315 0c-9.766434 27.322275-27.685549 51.116191-53.835116 71.65497-26.054399 20.446681-50.302663 34.288944-72.557526 41.61684l0 77.535911c42.346457-14.023388 79.07701-35.555797 110.197798-64.778352l0 322.720076 85.406159 0L572.751642 289.933345 572.751642 289.933345z" p-id="20949" fill="#2c2c2c"></path>
            </svg>
            <span style="vertical-align: middle;margin-left: 3%;">{{$t('moenySteps.i_download')}}</span>
          </div>
          <div style="text-align: left;margin-left: 8%;">
            <div style="margin-left: 36px;">
              <img src="@/assets/images/login001.png" style="border-radius: 10px;width: 60px;" />
            </div>
            <div style="margin-top: 3%;">
              <el-button type="primary" @click="downloadOk()" style="border-radius: 30px;width: 132px;height: 44px;background-color: rgba(195, 214, 62, 1);">
                <img width="16" src="@/assets/images/android.png" />
                &nbsp;
                <span style="font-size: 18px;">Android</span>
              </el-button>

              <!-- <el-button type="primary" @click="downloadOk()" style="width: 132px;border-radius: 30px;height: 44px;background-color: rgba(195, 214, 62, 1);">
                <img width="13" src="@/assets/images/ios.png" />
                &nbsp;
                <span style="font-size: 18px;">IOS</span>
              </el-button> -->
            </div>
          </div>

          <div v-if="isActivation == false">
            <div style="text-align: left;margin-top: 5%;margin-bottom: 3%;">
              <svg style="vertical-align: middle;" t="1656052745144" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12082" width="32" height="32">
                <path d="M511.950881 962.833919c-248.254084 0-449.561949-201.849194-449.561949-450.833919S263.696798 61.166081 511.950881 61.166081c248.353344 0 449.659163 201.848171 449.659163 450.832895S760.305249 962.833919 511.950881 962.833919L511.950881 962.833919zM662.141532 665.532769 492.320798 665.532769c4.433986-7.780197 10.224876-15.652492 17.46579-23.793916 7.143701-8.142447 24.246217-24.608467 51.2922-49.399083 27.053146-24.789593 45.695738-43.788296 56.095599-56.997132 15.566534-19.905352 26.962072-38.904055 34.202986-57.08923 7.147794-18.186198 10.765181-37.366026 10.765181-57.45148 0-35.374672-12.573363-64.87045-37.636179-88.665389-25.15389-23.793916-59.715033-35.646871-103.595426-35.646871-40.077787 0-73.464175 10.222829-100.246145 30.761608-26.689872 20.537755-42.61047 54.374398-47.676859 101.602026l85.408205 8.504698c1.629103-25.060769 7.688099-42.974767 18.0941-53.740972 10.492982-10.766205 24.608467-16.194844 42.340317-16.194844 17.915022 0 31.940456 5.15644 42.070164 15.380292 10.226922 10.313903 15.293311 24.970718 15.293311 44.061518 0 17.281595-5.884011 34.742269-17.640776 52.475142-8.69094 12.846586-32.212656 37.184901-70.665433 73.194022-47.772027 44.512796-79.794347 80.250742-95.994308 107.120716-16.192797 26.962072-25.877367 55.461149-29.127388 85.498256l299.375391 0L662.141532 665.532769 662.141532 665.532769z" p-id="12083" fill="#2c2c2c"></path>
              </svg>
              <span style="margin-left: 3%;vertical-align: middle;">{{$t('moenySteps.i_activate')}}</span>
            </div>
            <div style="text-align: left;margin-left: 8%;">
              <div style="margin-left: 52px;">
                <img src="@/assets/images/moneySteps004.png" style="width: 60px;" />
              </div>
              <div style="">
                <span style="vertical-align: middle;margin-right: 3%;">
                  {{$t('moenySteps.i_status')}}：<span style="color: red;">{{$t('moenySteps.i_activateN')}}</span>
                </span>
                <img width="30" src="@/assets/images/moneyStepsN.png" style="vertical-align: middle;" />
              </div>
              <div style="margin-top: 5%;">
                <el-button type="primary" @click="actDialog = true" :class="shrinkPacket" style="border-radius: 30px;height: 44px;min-width: 158px;background-color: rgba(195, 214, 62, 1);color: black;">{{$t('moenySteps.i_goActivate')}}</el-button>
              </div>
            </div>
          </div>

          <div v-if="isActivation == true">
            <div style="text-align: left;margin-top: 5%;margin-bottom: 3%;">
              <svg style="vertical-align: middle;" t="1656052745144" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12082" width="32" height="32">
                <path d="M511.950881 962.833919c-248.254084 0-449.561949-201.849194-449.561949-450.833919S263.696798 61.166081 511.950881 61.166081c248.353344 0 449.659163 201.848171 449.659163 450.832895S760.305249 962.833919 511.950881 962.833919L511.950881 962.833919zM662.141532 665.532769 492.320798 665.532769c4.433986-7.780197 10.224876-15.652492 17.46579-23.793916 7.143701-8.142447 24.246217-24.608467 51.2922-49.399083 27.053146-24.789593 45.695738-43.788296 56.095599-56.997132 15.566534-19.905352 26.962072-38.904055 34.202986-57.08923 7.147794-18.186198 10.765181-37.366026 10.765181-57.45148 0-35.374672-12.573363-64.87045-37.636179-88.665389-25.15389-23.793916-59.715033-35.646871-103.595426-35.646871-40.077787 0-73.464175 10.222829-100.246145 30.761608-26.689872 20.537755-42.61047 54.374398-47.676859 101.602026l85.408205 8.504698c1.629103-25.060769 7.688099-42.974767 18.0941-53.740972 10.492982-10.766205 24.608467-16.194844 42.340317-16.194844 17.915022 0 31.940456 5.15644 42.070164 15.380292 10.226922 10.313903 15.293311 24.970718 15.293311 44.061518 0 17.281595-5.884011 34.742269-17.640776 52.475142-8.69094 12.846586-32.212656 37.184901-70.665433 73.194022-47.772027 44.512796-79.794347 80.250742-95.994308 107.120716-16.192797 26.962072-25.877367 55.461149-29.127388 85.498256l299.375391 0L662.141532 665.532769 662.141532 665.532769z" p-id="12083" fill="#2c2c2c"></path>
              </svg>
              <span style="margin-left: 3%;vertical-align: middle;">{{$t('moenySteps.i_activate')}}</span>
            </div>
            <div style="text-align: left;margin-left: 8%;">
              <div style="margin-left: 52px;">
                <img src="@/assets/images/moneySteps004.png" style="width: 60px;" />
              </div>
              <div style="">
                <span style="vertical-align: middle;margin-right: 3%;">
                  {{$t('moenySteps.i_status')}}：<span style="color: rgba(119, 135, 142, 1);">{{$t('moenySteps.i_activateY')}}</span>
                </span>
                <img width="30" src="@/assets/images/moneyStepsY.png" style="vertical-align: middle;" />
              </div>
              <div style="margin-top: 5%;">
                <el-button type="info" style="border-radius: 30px;width: 158px;">{{$t('moenySteps.i_activateY')}}</el-button>
              </div>
            </div>
          </div>

          <div style="margin-top: 5%;margin-bottom: 3%;text-align: left;">
            <span style="">
              <svg style="vertical-align: middle;" t="1656052825604" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="18059" width="32" height="32">
                <path d="M513.1 64.2c-247.4 0-448 200.6-448 448s200.6 448 448 448 448-200.6 448-448-200.6-448-448-448z m99.2 656.1c-26.9 23-61.3 34.5-103.3 34.5-41.2 0-75-10.6-101.4-31.9-30.3-24.3-45.7-59.1-46.1-104.6H446c0.4 19.9 6.9 35.1 19.5 45.5 11.3 10 25.8 15 43.5 15 18.6 0 33.7-5.6 45.2-16.9 11.5-11.3 17.2-27.1 17.2-47.5 0-19.5-5.7-35-17.2-46.5s-27.4-17.2-47.8-17.2H494v-73.4h12.2c38.6 0 58-19.3 58-57.9 0-18.6-5.4-33.1-16.2-43.5-10.8-10.4-24.2-15.6-40.2-15.6-16 0-29.3 5-39.8 14.9-10.6 10-16.3 23.6-17.2 41h-84.2c0.9-40.3 14.7-72.4 41.6-96.2 26.9-23.8 60.2-35.8 100.1-35.8 41.2 0 75 12.4 101.4 37.1 26.4 24.7 39.7 56.5 39.7 95.5 0 44.2-17.8 75.8-53.3 94.9C636 532.9 655.9 568 655.9 617c0 44.1-14.6 78.6-43.6 103.3z" p-id="18060" fill="#2c2c2c"></path>
              </svg>
              <span style="vertical-align: middle;">&nbsp;&nbsp;{{$t('moenySteps.i_getCoins')}}&nbsp;&nbsp;</span>
              <img src="@/assets/images/moneySteps003.png" style="width: 25px;vertical-align: middle;" />
            </span>
          </div>
          <div style="background-color: rgba(171, 148, 130, 1);color: white;border-radius: 10px;padding: 3% 0;margin: 0 3%;">
            <div style="text-align: left;margin-left: 5%;margin-bottom: 3%;">{{$t('moenySteps.i_howToGetCoins')}}</div>
            <div style="overflow: hidden;text-align: center;width: 100%;margin: auto;">
              <div style="float: left;width: 25%;">
                <img src="@/assets/images/moneySteps005.png" style="width: 40px;" />
                <div>{{$t('moenySteps.i_chest')}}</div>
              </div>
              <div style="float: left;width: 25%;">
                <img src="@/assets/images/moneySteps006.png" style="width: 40px;" />
                <div>{{$t('moenySteps.i_luckyDraw')}}</div>
              </div>
              <div style="float: left;width: 25%;">
                <img src="@/assets/images/moneySteps007.png" style="width: 40px;" />
                <div>{{$t('task.i_taskT')}}</div>
              </div>
              <div style="float: left;margin-top: 20px;width: 25%;">
                <svg t="1659086055309" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5513" width="32" height="32">
                  <path d="M221 592c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z m291 0c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z m291 0c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z" p-id="5514" fill="white"></path>
                </svg>
              </div>
            </div>
          </div>
          <div style="margin-top: 5%;">
            <el-button type="primary" @click="moneyStepsOk()" style="text-align: center;border-radius: 30px;width: 158px;height: 44px;background-color: rgba(195, 214, 62, 1);color: black;">
              <img src="@/assets/images/moneySteps003.png" style="width: 16px;vertical-align: middle;" />&nbsp;
              <span style="font-size: 16px;vertical-align: middle;">{{$t('common.i_startEarning')}}</span>
            </el-button>
            <el-button type="primary" @click="moneyStepsOk()" style="text-align: center;border-radius: 30px;width: 158px;height: 44px;background-color: rgba(195, 214, 62, 1);">
              <router-link to="/task" style="text-decoration: none;color: black;">
                <span style="font-size: 16px;vertical-align: middle;">{{$t('task.i_task')}}</span>
              </router-link>
            </el-button>
          </div>

          <div style="margin-top: 5%;margin-bottom: 3%;text-align: left;">
            <span style="">
              <svg style="vertical-align: middle;" t="1659081776583" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4978" width="32" height="32">
                <path d="M512.047072 962.876897c-248.353344 0-449.657116-201.848171-449.657116-450.831872 0-249.074775 201.304795-450.921923 449.657116-450.921923 248.25613 0 449.562972 201.847148 449.562972 450.921923C961.610044 761.028727 760.303202 962.876897 512.047072 962.876897L512.047072 962.876897zM662.23056 566.962799l-55.094806 0L607.135754 282.96463l-71.746044 0L341.768921 567.233975l0 74.460876 182.672436 0 0 90.02127 82.694397 0 0-90.02127 55.094806 0L662.23056 566.962799 662.23056 566.962799zM524.441357 414.153508l0 152.809291L421.74951 566.962799 524.441357 414.153508 524.441357 414.153508z" p-id="4979" fill="#2C2C2C"></path>
              </svg>
              <span style="vertical-align: middle;">&nbsp;&nbsp;{{$t('moenySteps.i_coinsForCash')}}&nbsp;&nbsp;</span>
              <img src="@/assets/images/moneySteps008.png" style="width: 25px;vertical-align: middle;" />
            </span>
          </div>
          <div style="text-align: left;margin-top: 5%;margin-bottom: 10%;margin-left: 8%;">
            <el-button style="padding: 0;border-radius: 14px;position: relative;background: none;">
              <router-link to="/convert">
                <img width="164" src="@/assets/images/index002.png" />
                <span style="position: absolute;box-sizing: border-box;left: 12px;top: 12px;color: white;">
                  <span style="font-size: 14px;font-weight: bold;float: left;">{{$t('index.i_convert')}}</span>
                  <br />
                  <span style="font-size: 12px;font-weight: 100;margin-top: 3px;">{{$t('index.i_convertMsg')}}</span>
                </span>
              </router-link>
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="actDialog" width="80%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;">
        <span>{{$t('moenySteps.i_actTips')}}</span>
      </div>
      <div style="text-align: center;margin-top: 10%;">
        <el-button type="primary" @click="getActivation()">{{$t('convert.i_ok')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      activeName: "first",
      urlSchemes: "",
      isActivation: false,
      show: false,
      shrinkPacket: "",
      actDialog: false
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", this.isVisible);
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.isVisible);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getMoneyStepsInfo() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/webUser/moneySteps",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        this.isActivation = result.data.data.isActivation;
        this.urlSchemes = result.data.data.urlSchemes;
      });
    },
    downloadOk() {
      window.location =
        "https://luckycostudio.s3.ap-southeast-1.amazonaws.com/SheepMergeFight.html";
    },
    getActivation() {
      location.href = this.urlSchemes;
      this.actDialog = false;
    },
    isVisible: function (e) {
      if (e.target.visibilityState === "visible") {
        // 切入页面 显示页面
        if (this.isActivation == false) {
          //this.isActivation = true;
          this.getMoneyStepsInfo();
        }
      } else if (e.target.visibilityState === "hidden") {
        // 切出页面 隐藏页面
      }
    },
    moneyStepsOk() {
      if (!this.isActivation) {
        var timeCount = 3;
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          if (timeCount % 2 != 0 && timeCount > 0) {
            timeCount--;
            this.shrinkPacket = "hd1";
          } else if (timeCount == 0) {
            this.shrinkPacket = "";
            clearInterval(this.timer);
            this.timer = null;
            timeCount = 3;
          } else if (timeCount % 2 == 0 && timeCount > 0) {
            timeCount--;
            this.shrinkPacket = "hd2";
          }
        }, 150);
      } else {
        location.href = this.urlSchemes.substring(
          0,
          this.urlSchemes.indexOf("//") + 2
        );
      }
    }
  },
  created() {
    this.getMoneyStepsInfo();
    var ua = navigator.userAgent;
    var isWeixin = !!/MicroMessenger/i.test(ua);
    if (isWeixin) {
      this.show = true;
    }
  }
};
</script>

<style>
#moneySteps-layout {
  width: 100%;
  height: 100%;
}
.ms-page-header {
  height: 24px;
  padding-top: 4%;
  margin-left: 4%;
  margin-right: 5%;
}
.hd1 {
  transform: rotate(-5deg);
  width: 50%;
  height: 50px;
}
.hd2 {
  transform: rotate(3deg);
  width: 50%;
  height: 50px;
}
.popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
}
#moneySteps-layout .el-button {
  border: none;
}
</style>
