<template>
  <div class="login-index">
    <div id="login-layout">
      <div class="login-main">
        <div>
          <img src="@/assets/images/login001.png" class="avatar-css" />
        </div>
        <div style="margin-top: 4%;">
          <img src="@/assets/images/login002.png" style="width: 58%" />
        </div>

        <div style="margin-top: 2%;font-size: 18px;color: white;">- {{$t('login.i_playGames')}} -</div>
        <el-button type="success" @click="loginDialog = true" style="margin-top: 14%;width: 60%;height: 60px;font-size: 24px;border-radius: 8px;background-color: rgba(195, 214, 62, 1);color: black;">{{$t('common.i_startEarning')}}</el-button>
      </div>
    </div>
    <div class="login-footer">
      <img src="@/assets/images/login003.png" alt="" width="100%" />
    </div>

    <el-dialog :title="$t('login.i_login')" :visible.sync="loginDialog" width="84%" center class="login-dialog" v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" style="text-align: center;">
        <el-form-item :label="$t('login.i_enterYourEmail')" prop="email">
          <el-input type="mail" v-model="loginForm.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('login.i_enterYourPass')" prop="pass" required>
          <el-input type="password" v-model="loginForm.pass" show-password></el-input>
        </el-form-item>
        <div style="text-align: right;margin-bottom: 5%;">
          <router-link to="/forgotPassword" style="color: rgba(195, 214, 62, 1);">{{$t('login.i_forgotPass')}}</router-link>
        </div>
        <el-form-item>
          <el-button type="primary" @click="login('loginForm')" style="background-color: rgba(195, 214, 62, 1);color: black;border-radius: 30px;width: 50%;">{{$t('login.i_goLogin')}}</el-button>
        </el-form-item>
      </el-form>
      <div style="text-align: center;">
        <router-link to="/register" style="color: rgba(195, 214, 62, 1);">{{$t('login.i_goRegister')}}</router-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validatorPass = (rule, value, callback) => {
      var regex =
        /^[0-9]*$|^[a-zA-Z]*$|^[~!@#$%^&*]*$|^[A-Za-z0-9]*$|^[0-9~!@#$%^&*]*$|^[a-zA-Z~!@#$%^&*]*$|^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]*$/;
      if (!regex.test(value)) {
        callback(new Error(this.$t("register.i_specialSymbols")));
      } else if (value.length < 6) {
        callback(new Error(this.$t("register.i_pass6")));
      } else if (value.length > 30) {
        callback(new Error(this.$t("register.i_pass30")));
      } else {
        callback();
      }
    };
    return {
      isLoading: false,
      loginDialog: false,
      loginForm: {
        email: "",
        pass: ""
      },
      loginRules: {
        email: [
          {
            required: true,
            message: this.$t("register.i_emailAddressEmpty"),
            trigger: ["blur", "change"]
          },
          {
            type: "email",
            message: this.$t("register.i_checkEmail"),
            trigger: ["blur", "change"]
          }
        ],
        pass: [
          {
            required: true,
            message: this.$t("register.i_passEmpty"),
            trigger: ["blur", "change"]
          },
          {
            validator: validatorPass,
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.$axios({
            url: "/webAndGame/webUser/user/login",
            method: "post",
            params: this.loginForm
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              localStorage.setItem("token", result.data.data.token);
              this.$router.push("/index");
            } else {
              this.$message({
                showClose: true,
                message: result.data.message,
                type: "error",
                center: true
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style>
.login-index {
  width: 100%;
  height: 100%;
  background: url("@/assets/images/loginBackground.png") no-repeat;
  background-size: 100%;
}
#login-layout {
  height: auto;
  min-height: 100%;
}
.login-main {
  padding-bottom: 72.2%;
  margin-bottom: 15%;
}
#login-layout .avatar-css {
  margin-top: 18%;
  width: 30%;
  border-radius: 8px;
}
#login-layout .el-dialog__header,
#login-layout .el-dialog__body {
  background: white;
}
.login-footer {
  position: relative;
  clear: both;
  margin-top: -72.2%;
  height: 0;
}
.login-index .el-dialog__title {
  color: white !important;
}
</style>
