<template>
  <div v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-log-layout">
      <div class="il-page-header">
        <el-page-header :title="$t('common.i_back')" :content="$t('inviteFriend.i_inviteLog')" @back="$router.push('/inviteFriend')" style="float: left;"></el-page-header>
        <div style="float: right;background-color: rgba(22, 21, 16, 1);padding: 2px 6px 3px 6px;border-radius: 20px;">
          <img src="@/assets/common/moneyIcon.png" style="width: 14px;vertical-align: middle;" />
          <span style="vertical-align: middle;text-decoration: none;color: white;font-size: 14px;padding-right: 2px;"> {{$store.state.goldNum}}</span>
        </div>
      </div>
      <div style="">
        <el-tabs type="border-card" style="">
          <el-tab-pane :label="$t('moenySteps.i_activateY')" style="">
            <div style="overflow-y: scroll;height: 81.5vh;">
              <div v-infinite-scroll="loadMoreOne" infinite-scroll-disabled="busyOne" infinite-scroll-distance="10">
                <div v-for="log in inviteLogOne" :key="log.id">
                  <div style="background-color: rgba(22, 21, 16, 1);color: white;margin-bottom: 3%;border-radius: 8px;padding: 4%;">
                    <div style="overflow:hidden;">
                      <div style="float: left;">
                        WID：{{log.wid}}
                        <div style="margin-top: 10%;color: rgba(255, 176, 30, 1);">
                          {{log.inviteesTime.substring(0,10)}}
                        </div>
                      </div>
                      <div style="float: right;font-size: 18px;margin-top: 5%;">
                        <div style="float: left;margin-top: 3%;color: rgba(255, 176, 30, 1);">+{{log.inviteReward}}</div>
                        <img src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/webAndGame/common/index004.png" style="width: 20px;" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="inviteLogOne==''" style="text-align: center;">
                  <img width="150" src="@/assets/common/noIcon.png" style="margin-top: 30%;" />
                  <div style="color: rgba(221, 154, 107, 1);margin-top: 5%;">{{$t('inviteFriend.i_noLog')}}</div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('moenySteps.i_activateN')" style="">
            <div style="overflow-y: scroll;height: 81.5vh;">
              <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                <div v-for="log in inviteLog" :key="log.id">
                  <div style="background-color: rgba(22, 21, 16, 1);color: white;margin-bottom: 3%;border-radius: 8px;padding: 4%;">
                    <div style="overflow: hidden;">
                      <div style="float: left;">
                        <span>WID：{{log.wid}}</span>
                        <div style="margin-top: 10%;color: rgba(255, 176, 30, 1);">
                          {{log.inviteesTime.substring(0,10)}}
                        </div>
                      </div>
                      <div style="float: right;font-size: 18px;">
                        <div style="overflow: hidden;">
                          <img style="float: right;width: 20px;" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/webAndGame/common/index004.png" />
                          <span style="float: right;color: rgba(255, 176, 30, 1);">+{{log.inviteReward}}</span>
                        </div>
                        <div style="font-size: 12px;color: rgba(158, 59, 0, 1);margin-top: 8px;">{{$t('inviteFriend.i_notActivated')}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="inviteLog==''" style="text-align: center;">
                <img width="150" src="@/assets/common/noIcon.png" style="margin-top: 30%;" />
                <div style="color: rgba(221, 154, 107, 1);margin-top: 5%;">{{$t('inviteFriend.i_noLog')}}</div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      inviteLogOne: [],
      busyOne: false,
      pageNumOne: 0,
      isLastOne: false,
      inviteLog: [],
      busy: false,
      pageNum: 0,
      isLast: false
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    loadMoreOne: function () {
      if (this.isLastOne != true) {
        this.busyOne = true;
        this.pageNumOne++;

        this.isLoading = true;
        this.$axios({
          url: "/webAndGame/web/invitationRecord",
          method: "get",
          params: {
            pageNum: this.pageNumOne,
            state: 1
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.$store.state.goldNum = result.data.data.goldNum;
            result.data.data.inviteRelation.forEach((element) => {
              this.inviteLogOne.push(element);
            });
            this.isLastOne = result.data.data.isLast;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });

        this.busyOne = false;
      }
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;

        this.isLoading = true;
        this.$axios({
          url: "/webAndGame/web/invitationRecord",
          method: "get",
          params: {
            pageNum: this.pageNum,
            state: 0
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.$store.state.goldNum = result.data.data.goldNum;
            result.data.data.inviteRelation.forEach((element) => {
              this.inviteLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
        this.busy = false;
      }
    }
  },
  created() {}
};
</script>

<style>
#invite-log-layout {
  width: 100%;
  height: 100%;
}
#invite-log-layout .il-page-header {
  padding: 4%;
  overflow: hidden;
}
#invite-log-layout .is-top {
  background: rgba(59, 56, 47, 1);
  color: white;
}
#invite-log-layout .is-active {
  background: rgba(39, 37, 30, 1) !important;
  color: white;
}
#invite-log-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}
#invite-log-layout .el-tabs--border-card {
  background: rgba(39, 37, 30, 1) !important;
  border: none !important;
  box-shadow: none !important;
}
</style>
