import Vue from "vue";
import VueRouter from "vue-router";
import Invite from "@/views/invite/Invite.vue";
import Register from "@/views/Register.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Login from "@/views/Login.vue";
import Convert from "@/views/Convert.vue";
import ConvertLog from "@/views/ConvertLog.vue";
import GoldLog from "@/views/GoldLog.vue";
import Index from "@/views/Index.vue";
import InviteFriend from "@/views/InviteFriend.vue";
import ConvertInfo from "@/views/ConvertInfo.vue";
import MoneySteps from "@/views/MoneySteps.vue";
import InviteLog from "@/views/InviteLog.vue";
import Task from "@/views/Task.vue";
import Leaderboard from "@/views/Leaderboard.vue";
import Cs from "@/views/Cs.vue";
import Cs1 from "@/views/Cs1.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "index"
  },
  {
    path: "/invite",
    name: "invite",
    component: Invite
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/convert",
    name: "convert",
    component: Convert
  },
  {
    path: "/convertLog",
    name: "convertLog",
    component: ConvertLog
  },
  {
    path: "/goldLog",
    name: "goldLog",
    component: GoldLog
  },
  {
    path: "/index",
    name: "index",
    component: Index
  },
  {
    path: "/inviteFriend",
    name: "inviteFriend",
    component: InviteFriend
  },
  {
    path: "/convertInfo/:id",
    name: "convertInfo",
    props: true,
    component: ConvertInfo
  },
  {
    path: "/moneySteps",
    name: "moneySteps",
    component: MoneySteps
  },
  {
    path: "/inviteLog",
    name: "inviteLog",
    component: InviteLog
  },
  {
    path: "/task",
    name: "task",
    component: Task
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: Leaderboard
  },
  {
    path: "/cs",
    name: "cs",
    component: Cs
  },
  {
    path: "/cs1",
    name: "cs1",
    component: Cs1
  }
];

const router = new VueRouter({
  routes
});

// // 为路由对象 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//   // to 将要访问的的路径
//   // from 代表从哪个路径跳转
//   // next 是一个函数 表示放行 next() 放行 next('/login') 强制跳转
//   // 如果用户访问的登录页面，直接放行
//   if (to.path == '/invite') return next()
//   if (to.path == '/register') return next()
//   if (to.path == '/login') return next()
//   if (to.path == '/forgotPassword') return next()
//   // 从sessionStorage中获取到保存的 token值
//   const tokenStr = window.localStorage.getItem('token')
//   // 没有token 强制跳转到登录页
//   if (!tokenStr) return next('/login')
//   next()
// });

export default router;
