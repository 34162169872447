<template>
  <div v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)" style="min-height: 100vh;">
    <div id="convert-layout">
      <div class="c-page-header">
        <el-page-header :title="$t('common.i_back')" :content="$t('convert.i_convert')" @back="$router.push('/index')" style="float: left"></el-page-header>
        <div style="float: right;height: 24px;">
          <svg style="vertical-align: middle;" t="1655452993620" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2356" width="24" height="24" fill="white">
            <path d="M783.53 240.47C641.08 98.02 413.8 91.41 263.09 220.03V167.1c0-17.67-14.33-32-32-32s-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32s-14.33-32-32-32h-48.15c125.55-101.54 310.66-94.06 427.34 22.62 124.75 124.77 124.75 327.8 0 452.56-124.78 124.75-327.78 124.75-452.56 0C225.28 677.84 192 597.48 192 512c0-17.67-14.33-32-32-32s-32 14.33-32 32c0 102.58 39.94 199.02 112.47 271.53 74.86 74.86 173.19 112.3 271.53 112.3 98.33 0 196.67-37.44 271.53-112.3 149.7-149.72 149.7-393.34 0-543.06z" p-id="2357"></path>
            <path d="M512 288c-17.67 0-32 14.33-32 32v185.38c0 12.81 5 24.88 14.06 33.94l123.31 123.31c6.25 6.25 14.44 9.38 22.62 9.38s16.38-3.12 22.62-9.38c12.5-12.5 12.5-32.75 0-45.25L544 498.75V320c0-17.67-14.33-32-32-32z" p-id="2358"></path>
          </svg>
          <router-link to="/convertLog" style="text-decoration: none;color: white;vertical-align: middle;">{{$t('convert.i_convertLog')}}</router-link>
        </div>
      </div>
      <div style="margin-top: 12%;color: white;">{{$t('convert.i_convertT')}}</div>

      <div style="margin-top: 5%;">
        <img src="@/assets/common/moneyIcon.png" style="width: 30px;" />
        <span style="font-size: 42px;color: white;font-weight: bold;"> {{$store.state.goldNum}}</span>
      </div>

      <img src="@/assets/images/convertIcon.png" style="width: 100px;margin-top: 10%;" />

      <div style="text-align: left;margin-left: 5%;margin-top: 14%;margin-bottom: 5%;color: white;">
        {{$t('convert.i_choose')}}
      </div>
    </div>

    <div>
      <span v-for="giftCard in giftCards" v-bind:key="giftCard.cardId">
        <router-link :to="{name: 'convertInfo', params: {id: giftCard.cardId}}">
          <img width="160" :src="giftCard.image" style="margin: 2%;border-radius: 8px;" />
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      giftCards: []
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getGiftCardList() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/web/listV2",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.giftCards = result.data.data.giftCards;
          sessionStorage.setItem(
            "giftCards",
            JSON.stringify(result.data.data.giftCards)
          );
          sessionStorage.setItem(
            "goldNum",
            JSON.stringify(result.data.data.goldNum)
          );
          this.$store.state.goldNum = result.data.data.goldNum;
        } else if (result.data.code == 2) {
          this.$store.state.goldNum = result.data.data.goldNum;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    }
  },
  created() {
    this.getGiftCardList();
  }
};
</script>

<style>
#convert-layout {
  width: 100%;
  height: 100%;
}
.c-page-header {
  padding-top: 4%;
  padding-left: 4%;
  padding-right: 4%;
}
</style>
