<template>
  <div>
    <div>
      <div class="my-outbox" ref="wrap">
        <div class="my-inbox" ref="content">
          <div class="my-list">
            1无缝滚动2343245436346433654634636463无缝滚动无缝滚动无缝滚动无缝滚动无缝滚动无缝滚动9
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      wrapWidth: 0,
      contentWidth: 0
    };
  },
  created() {},
  mounted() {
    this.start();
  },
  methods: {
    start() {
      const { wrap, content } = this.$refs;
      this.wrapWidth = wrap.getBoundingClientRect().width - 22;
      this.contentWidth = content.getBoundingClientRect().width;
      this.animateFn();
    },
    animateFn() {
      const { content } = this.$refs;
      let distance = this.wrapWidth;
      content.style.transform = "translateX(" + distance + "px)"; //初始值
      let that = this;
      setInterval(function () {
        distance = distance - 1;
        if (-distance >= that.contentWidth) {
          distance = that.wrapWidth;
        }
        content.style.transform = "translateX(" + distance + "px)";
      }, 30);
      //控制速度
    }
  },
  // 更新的时候运动
  updated: function () {
    this.animateFn();
  }
};
</script>

<style>
.my-outbox {
  color: #333;
  overflow: hidden;
  width: 60px;
  height: 35px;
  margin-left: 5%;
  position: relative;
}
.my-inbox {
  white-space: nowrap;
  position: absolute;
  font-size: 0;
}
.my-list {
  margin-right: 25px;
  display: inline-block;
  font-size: 13px;
  height: 35px;
  line-height: 35px;
}
</style>
