<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
html {
  width: 100%;
  height: 100%;
  background-color: rgba(59, 56, 47, 1);
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.el-loading-spinner .path {
  stroke: white !important;
}
.el-loading-spinner .el-loading-text {
  color: white !important;
}
.el-page-header__left {
  margin-right: 16px !important;
}
.el-page-header__left::after {
  right: -8px !important;
}
.el-page-header__left::after {
  background-color: white !important;
}
.el-page-header,
.el-page-header__content,
.el-icon-close:before {
  color: white !important;
}
.el-dialog {
  border-radius: 15px !important;
}
.el-dialog__header {
  border-radius: 15px 15px 0 0 !important;
  background-color: rgba(59, 56, 47, 1);
}
.el-dialog__body {
  border-radius: 0 0 15px 15px !important;
  background-color: rgba(59, 56, 47, 1);
  color: white !important;
}
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  color: red !important;
}
.el-form-item__label {
  color: white !important;
}
</style>
