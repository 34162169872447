<template>
  <div id="invite-layout">
    <img src="@/assets/images/invite000.png" style="width: 100%;" />
    <img v-if="$t('invite.inviteImg')=='zh'" src="@/assets/images/invite001.png" style="width: 100%" />
    <img v-if="$t('invite.inviteImg')=='en'" src="@/assets/images/invite001-1.png" style="width: 100%" />
    <el-main style="margin-top: 20%;"></el-main>
    <div class="invite-footer">
      <div class="invite-code">
        {{$t('inviteFriend.i_code')}}：
        <div class="invite-code-text">
          <span @click="copyVal($event.target.innerText)">{{inviteCode}}</span>
          <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
            <img src="@/assets/common/copyIconL.png" style="width: 12px;margin-bottom: 10px;" />
          </span>
        </div>
      </div>

      <el-button type="success" @click="register($event)" style="border-radius: 30px;margin-bottom: 5%;background-color: rgba(244, 242, 34, 1);">
        <img src="@/assets/images/login001.png" style="width: 30px;border-radius: 6px;float: left;" />
        <span style="font-size: 18px;margin-left: 10px;line-height: 30px;color: black;">
          Sheep Merge Money
          <el-icon class="el-icon-right" style="margin-left: 8px;"></el-icon>
        </span>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inviteCode: ""
    };
  },
  methods: {
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    register(e) {
      if (localStorage.getItem("token") != null) {
        this.$router.push("/index");
      } else {
        this.$router.push(
          "/register?inviteCode=" +
            e.currentTarget.parentElement.firstElementChild.firstElementChild
              .firstElementChild.innerText
        );
      }
    }
  },
  created() {
    this.inviteCode = this.$route.query.referrer;
  }
};
</script>

<style>
#invite-layout {
  background: linear-gradient(
      180deg,
      rgba(65, 201, 137, 1) 0%,
      rgba(65, 201, 137, 1) 0.12%,
      rgba(65, 168, 120, 1) 99.37%,
      rgba(65, 168, 120, 1) 100%
    )
    no-repeat;
  width: 100%;
  height: 100%;
}
.el-image {
  width: 80%;
}
.invite-code {
  margin-bottom: 10%;
  font-size: 20px;
}
.invite-code-text {
  color: rgba(19, 100, 62, 1);
  display: inline;
}
</style>
